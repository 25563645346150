import React from "react";
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import {
  HeaderContainer,
  Section,
  Bar,
  LinkList,
  MenuLink,
  Logo,
  BurgerMenu,
  SubLinks,
  MenuLinkList,
} from "./Header.styles";

const path = (typename) => {
  const atypical = { "Minivan/Van": "van", "Sedan/Hatchback/Wagon": "sedan" };
  return atypical[typename] || typename;
};

const simpleTypeName = (typename) => {
  const atypical = { "Minivan/Van": "Van", "Sedan/Hatchback/Wagon": "Sedan" };
  return atypical[typename] || typename;
};

export const Header: React.FC = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query CarData {
      allCarData {
        group(field: CarTypeTeoalida) {
          fieldValue
          nodes {
            VehicleSizeCategory
          }
        }
      }
    }
  `);
  const types = data.allCarData.group
    .map((g) => [
      g.fieldValue,
      [...new Set(g.nodes.map((n) => n.VehicleSizeCategory))],
    ])
    .sort((a, b) =>
      simpleTypeName(a[0]).toLowerCase() < simpleTypeName(b[0]).toLowerCase()
        ? -1
        : 1
    );

  return (
    <HeaderContainer>
      <Section>
        <Bar>
          <GatsbyLink to="/">
            <Logo>
              <img src="/favicon.png" alt="Logo" />
              Real Safe Cars
            </Logo>
          </GatsbyLink>
          <BurgerMenu>
            <LinkList>
              <MenuLinkList>
                <GatsbyLink to="/about">About</GatsbyLink>
              </MenuLinkList>
              <MenuLinkList>
                <GatsbyLink to="/search">Search</GatsbyLink>
              </MenuLinkList>
              <MenuLinkList>
                Ratings
                <SubLinks>
                  {types.map(([type]) => {
                    return (
                      <MenuLink
                        selected={pageContext && type === pageContext.carType}
                        to={`/${path(type)}s/all`.toLowerCase()}
                      >
                        {simpleTypeName(type)}s
                      </MenuLink>
                    );
                  })}
                </SubLinks>
              </MenuLinkList>
            </LinkList>
          </BurgerMenu>
        </Bar>
      </Section>
    </HeaderContainer>
  );
};
