import React from "react";
import styled, { css } from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { ifProp } from "ifprop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mediaQueryBuilder from "helpers/mediaQueryBuilder";
import variables from "../../variables";

export const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  background: linear-gradient(180deg, white 80%, rgba(0, 0, 0, 0));
  left: 50%;
  min-width: 100%;
  transform: translateX(-50%);
  z-index: 1000;
`;

const StyledInside = styled.div`
  max-width: 1000px;
  margin: auto;
  font-size: 0.8rem;
  ${mediaQueryBuilder.widthTablet(css`
    font-size: 1rem;
  `)}
`;

export const Section: React.FC = ({ children }) => (
  <StyledInside>{children}</StyledInside>
);

export const Bar = styled.div`
  padding: 0.5em 2em;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  //background: linear-gradient(45deg,#6132fc 3%,#4005e1 17%,#774ff9 57%,#4005e1 93%);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 80%,
    rgba(97, 50, 252, 0.1) 93%,
    rgba(97, 50, 252, 0.3)
  );
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 80%,
    ${variables.primaryColor} 81%,
    rgba(97, 50, 252, 0.05) 87%,
    rgba(0, 0, 0, 0)
  );
  border-radius: 20px;
  //border: 2px solid #4005e3 ;
  color: #171c65;
`;

export const Logo = styled.div`
  font-size: 1.7rem;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0.3em;
  color: ${variables.primaryColor};
  font-weight: bolder;
  font-family: "Adamina", serif;
  img {
    width: 35px;
    transform: translateY(2px);
    ${mediaQueryBuilder.widthTablet(css`
      width: 30px;
    `)}
  }
`;

export const LinkList = styled.div`
  display: flex;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    height: 100vh;
    align-items: flex-start;
  }
  /* ${mediaQueryBuilder.widthTablet(css` */
    /* flex-direction: row; */
  /* `)} */
`;

export const MenuLinkList = styled.li`
  position: relative;
  padding: 0.5em 0.5em;
  margin: 0;
  font-size: 1.2rem;
  ${mediaQueryBuilder.widthDesktop(css`
    font-size: 1em;
    padding: 0.5em 0.5em;
  `)}
  list-style: none;
  color: black;
  a {
    color: black;
  }
  ${(props) => props.selected && "color: #0ea93a;"}
`;

export const SubLinks = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  padding: 0.1em 1em;
  margin: 0.1em;
  ${mediaQueryBuilder.widthTablet(css`
    display: none;
    position: absolute;
    top: 90%;
    right: 50%;
    transform: translateX(50%);
    width: 100%;
    min-width: 6em;
    margin: 0;
    background: white;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-top-width: 0;
    padding: 0.3em;
    ${MenuLinkList}:hover & {
      display: flex;
    }
  `)}
`;

export const MenuLink = styled(GatsbyLink)`
  color: black;
  position: relative;
  ${mediaQueryBuilder.widthDesktop(css`
    margin: 0em 0em 0.5em 1em;
  `)}
  &:hover {
    color: ${variables.primaryColorLight};
  }
  ${(props) => props.selected && `color: ${variables.primaryColorLight};`}
`;

const BurgerContainer = styled.div``;

const BurgerIcon = styled.div`
  z-index: 1001;
  position: absolute;
  top: 0.4em;
  right: 0.5em;
  font-size: 30px;
  ${mediaQueryBuilder.widthTablet(css`
    display: none;
  `)}
`;

export const LinkContainer = styled.div`
  transition: transform 0.3s, opacity 0.5s;
  @media screen and (max-width: 800px) {
    left: 0;
    position: absolute;
    width: 100vw;
    font-size: 2em;
    top: 0;
    transform: translateX(100%);
    opacity: 0;
    ${ifProp({
      opened: css`
        background: white;
        width: 100vw;
        opacity: 1;
        transform: translateX(0%);
      `,
    })};
  }
`;

export class BurgerMenu extends React.Component {
  constructor() {
    super();
    this.state = { opened: false };
  }

  componentWillReceiveProps(prevProps) {
    const { location } = this.props;
    /* if (location.pathname !== prevProps.location.pathname) { */
    /* this.setState({ opened: false }); */
    /* } */
  }

  render() {
    const { children } = this.props;
    const { opened } = this.state;
    return (
      <BurgerContainer>
        <LinkContainer opened={opened}>{children}</LinkContainer>
        <BurgerIcon onClick={() => this.setState({ opened: !opened })}>
          <FontAwesomeIcon icon={opened ? "times" : "bars"} />
        </BurgerIcon>
      </BurgerContainer>
    );
  }
}
