import { css } from 'styled-components';

const query = (val, queryCss) => css`
  @media screen and (${val}) {
    ${queryCss}
  }
`;

const mediaQueryBuilder = {
  widthMobile: queryCss => query('min-width: 500px', queryCss),
  maxWidthMobile: queryCss => query('max-width: 500px', queryCss),
  widthTablet: queryCss => query('min-width: 800px', queryCss),
  widthDesktop: queryCss => query('min-width: 1000px', queryCss),
  heightMobile: queryCss => query('min-height: 500px', queryCss),
  heightTablet: queryCss => query('min-height: 800px', queryCss),
  heightDesktop: queryCss => query('min-height: 1000px', queryCss),
};

export default mediaQueryBuilder;
