module.exports = {
  primaryColor: "#1c301c", // f'#5005e3',
  primaryColorLight: "#0ea93a", // f'#5005e3',
  veryUnsafe: '#d65250',
  unsafe: '#d65250',
  somewhatUnsafe: "#d65250",
  unsafe: '#d78b00',
  belowAverage: "#d78b00",
  average: "#d78b00",
  aboveAverage:  "#4da103",
  safe: "#4da103",
  verySafe: "#4da103",
}

